import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';

export interface Mail {
  subject: string;
  to: string;
  cc: string;
  body: string;
}

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private http: HttpClient) { }

  public sendContactOrDemoForm(data: Mail) {
    return this.http.post(environment.urlApiSendEmail, data);
  }
}
