import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { } from 'googlemaps'
import { environment } from 'src/environments/environment';

export type Location = {
  lat: string,
  lng: string,
  address: string
}

export type Contact = {
  email: string,
  phoneNumber: string,
  phoneLabel: string
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {

  @ViewChild('map') mapElement: any;
  public map?: google.maps.Map;

  public location: Location | null = null;

  public contact?: Contact;

  public instagramAccount: string = environment.instagramAccount;

  constructor(private cdref: ChangeDetectorRef) {
    this.contact = environment.contact;
  }

  ngAfterViewInit(): void {
    this.location = environment.location;
    const mapProperties = {
      center: new google.maps.LatLng(+this.location.lat, +this.location.lng),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    setTimeout(() => {
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    }, 1000);
    this.cdref.detectChanges();
  }
}
