<iframe
  style="
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    padding: 0;
    margin: 0;
  "
  src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGAu827XjE&#x2F;gMLwzGvAAR3mb9z0uV9g1Q&#x2F;view?embed"
  allowfullscreen="allowfullscreen"
  allow="fullscreen"
></iframe>
