import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-durex',
  templateUrl: './durex.component.html',
  styleUrls: ['./durex.component.scss']
})
export class DurexComponent {

  constructor(title: Title) {
    title.setTitle('Bases y Condiciones sorteo Durex');
  }

}
