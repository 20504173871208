import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(private title: Title, private meta: Meta) {
    title.setTitle(`Eglow ${environment.domain}`)
    this.meta.addTags([
      { name: 'description', content: 'Somos un marketplace que utiliza tecnología efectiva para crear campañas de marketing con celebrities en sus redes sociales.' },
      { name: 'author', content: 'Eglow' },
      { name: 'keywords', content: `Eglow, Influencers, Influencers Marketplace, Social Networks, Instagramers, Tiktokers, Streamers, Eglow ${environment.domain}` }
    ])
  }
}
