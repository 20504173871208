<section id="inicio" class="bg-gray-100 py-10">
  <div class="container mx-auto px-4">
    <h1 class="text-4xl font-bold text-center text-white">INFLUENCERS PARA TU MARCA</h1>
  </div>
  <div class="container mx-auto px-4">
    <p class="description text-white">
      Somos un marketplace que utiliza <span>tecnología efectiva</span> para crear campañas de marketing con <span>celebrities</span> en sus <span>redes sociales</span>.
    </p>
  </div>
  <div class="container mx-auto px-4 text-center">
    <a href="https://eglowonline.com/auth/login" target="_blank">Soy una Marca</a>
    <a href="https://eglowonline.com/auth/login" target="_blank">Soy un Influencer</a>
  </div>
</section>
