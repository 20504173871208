import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

export type SuccessfulCases = {
  title: string,
  text: string,
  metrics: string,
  photo: string
}

@Component({
  selector: 'app-successful-cases',
  templateUrl: './successful-cases.component.html',
  styleUrls: ['./successful-cases.component.scss']
})
export class SuccessfulCasesComponent implements OnInit {

  public cases: Array<SuccessfulCases> = []

  public ngOnInit(): void {
      this.cases = environment.successfulCases;
  }

}
