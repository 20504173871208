import {
  Component,
  ElementRef,
  ViewChild,
  effect,
  signal,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MailService } from '../../services/mail.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {

  public toggle = signal(false);

  public modalIsOpen: boolean = false;
  public modalContactIsOpen: boolean = false;
  public modalDemoIsOpen: boolean = false;

  public titleModal?: string;

  public formIsSubmitted = false;
  public contactForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    companyName: ['', Validators.required],
    phoneNumber: ['', Validators.required],
    countEmployees: ['1-10'],
    country: ['México'],
    message: ['', Validators.required],
  });
  public mailSentSuccessfully: boolean = false;

  public sendingEmail: boolean = false;

  constructor(private fb: FormBuilder, private mailService: MailService) {}

  public clickToggle(): void {
    this.toggle.set(!this.toggle());
  }

  public openModal(modal: 'DEMO' | 'CONTACT'): void {
    this.resetForm();
    this.modalIsOpen = true;
    if (modal === 'DEMO') {
      this.modalDemoIsOpen = true;
      this.titleModal = 'Schedule a Demo';
      this.contactForm.get('message')?.clearValidators();
      this.contactForm.get('message')?.updateValueAndValidity();
    }

    if (modal === 'CONTACT') {
      this.modalContactIsOpen = true;
      this.titleModal = 'Contact Us';
      this.contactForm.get('message')?.addValidators(Validators.required);
      this.contactForm.get('message')?.updateValueAndValidity();
    }
  }

  public closeModal(): void {
    this.modalIsOpen = false;
    this.modalContactIsOpen = false;
    this.modalDemoIsOpen = false;
    this.formIsSubmitted = false;
  }

  public validateContatForm(): void {
    this.formIsSubmitted = true;
    if (this.contactForm.valid) {
      this.sendingEmail = true;
      this.mailService.sendContactOrDemoForm({
        to: environment.mailRecipients.to,
        cc: environment.mailRecipients.cc,
        subject: this.modalContactIsOpen ?
          `Contact from ${environment.domainUrl}` : `Schedule a Demo from ${environment.domainUrl}`,
        body: this.getBodyContactForm()
      }).subscribe({
        next: (result: any) => {
          this.mailSentSuccessfully = true;
          this.resetForm();
          this.formIsSubmitted = false;
          this.sendingEmail = false;
          setTimeout(() => this.mailSentSuccessfully = false, 5000);
        },
        error: (err) => {
          this.sendingEmail = false;
          console.error(err);
        }
      })
    }
  }

  private resetForm(): void {
    this.contactForm.patchValue({
      name: '',
      email: '',
      companyName: '',
      phoneNumber: '',
      countEmployees: '1-10',
      country: environment.domain,
      message: ''
    });
  }

  private getBodyContactForm(): string {
      let body = `
        <b>Name</b>: ${this.contactForm.get('name')?.value} <br>
        <b>Email</b>: ${this.contactForm.get('email')?.value} <br>
        <b>Company Name</b>: ${this.contactForm.get('companyName')?.value} <br>
        <b>Telephone</b>: ${this.contactForm.get('phoneNumber')?.value} <br>
        <b>Employees</b>: ${this.contactForm.get('countEmployees')?.value} <br>
        <b>Country</b>: ${this.contactForm.get('country')?.value} <br>
      `;
      if (this.modalContactIsOpen) {
        body += `
        <b>Message</b>: ${this.contactForm.get('message')?.value} <br>
        `;
      }
      return body;
  }
}
