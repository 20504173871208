<div class="main-container container">
  <figure>
    <img class="logo" src="/assets/img/durex-logo.png" alt="">
  </figure>

  <div class="title px-10">
    <h2 class="text-3xl">¡BIENVENIDO AL</h2>
    <h1 class="text-4xl font-bold">FUTURO DUREX!</h1>
  </div>
  <div class="description px-10">
    <div>
      <h3 class="text-2xl font-bold">¡Ya eres uno de nosotros!</h3>
      <p class="text-2xl">Hoy elegiste y te llevamos al <span class="font-bold">siguiente nivel.</span></p>
    </div>

    <h3 class="text-2xl font-bold mt-5">🚨 CONCURSO DUREX</h3>
    <p class="text-2xl font-bold">
      Gana una de las 2 entradas dobles para La Feria On Tour: Hernan Cattaneo + 2 Durex Box y vive la experiencia con el Team Durex 🛸
    </p>
    <!-- <p class="text-2xl font-bold">
      una Durex Box Gold
    </p> -->
  </div>
  <div class="requirements mt-7">
    <h3 class="text-2xl my-3">¿Cómo participar?</h3>
    <ol class="text-2xl">
      <li>Dar ❤️ a este posteo</li>
      <li>Etiquetar a la persona con la que te gustaría compartir el premio</li>
      <li>Ambas cuentas deben seguir a @Durex_CL</li>
      <li>Sube esta publicación en tus historias etiquetándonos</li>
    </ol>
    <p>Y listo ¡Ya estás participando!</p>
    <p>Los ganadores serán anunciados el jueves 25 de enero a través de nuestras historias. 📲</p>
  </div>
  <div class="modalidites mt-7">
    <h3 class="text-lg font-bold">BASES CONCURSO</h3>
    <h4 class="text-lg mb-3">La Feria On Tour: Hernan Cattaneo</h4>
    <div class="container mx-auto xl:px-80 sm:px-0">
      <p class="text-sm px-5">
        DUREX (@durex_cl) ha decidido efectuar un concurso con una modalidad única de participación y premiación,
        desde ahora denominado CONCURSO, el que se regirá por las bases y reglas que se exponen a continuación,
        desde ahora denominadas BASES:<br>

        Modalidad del CONCURSO: <br>

        El CONCURSO, tendrá 1 modalidad de participación a través de la red social Instagram, en el
        que deberás seguir a la cuenta de @durex_cl y etiquetar a 1 usuario en un comentario. <br>

        El comentario y la etiqueta al usuario, se debe realizar en la publicación del CONCURSO
        (publicada en las redes sociales de Durex Chile) y tanto tú como la persona que etiquetaste deben
        seguir a la cuenta @durex_cl.
      </p>
    </div>
  </div>
  <div class="awards mt-7">
    <h3 class="text-lg font-bold">PREMIO:</h3>
    <div class="container mx-auto xl:px-80 sm:px-0 text-sm">
      <p class="px-5 pb-2">En el contexto del CONCURSO podrás ganar:</p>

      <p class="px-5">
        • Una de las dos entradas dobles para asistir al evento La Feria On Tour: Hernan Cattaneo programado para el día 27 de enero 2024. <br>
        • Una "Durex Box" con productos Durex varios. <br><br>
      </p>
      <p class="px-5">
        Bajo las condiciones establecidas en estas BASES y en particular conforme a los siguientes
        términos (el “PREMIO”):
      </p>

      <ol class="mt-5 px-5">
        <li>
          El PREMIO será válido sólo para el ganador seleccionado de conformidad a lo
          dispuesto en estas BASES, no extendiéndose el beneficio a otros.
        </li>
        <li>
          El PREMIO será válido sólo para el ganador seleccionado de conformidad a lo
          dispuesto en estas BASES, no extendiéndose el beneficio a otros.
        </li>
        <li>
          El PREMIO sólo será efectivo para aquellos mayores de 18 años.
          Menores de edad no podrán hacer goce del PREMIO. Se le solicitará a los ganadores la información
          correspondiente para comprobar su identidad y su edad acorde a estas BASES.
        </li>
        <li>
          El PREMIO no incluirá ningún otro pago, prestación, bien o servicio distinto de
          aquello indicado expresamente en las presentes BASES, siendo intransferible y el
          Ganador no podrá cederlo. Todos los costos que se deriven de la participación o de la
          obtención del PREMIO , así como también los gastos de traslado, alimentos, bebidas,
          entre otros, en que incurra al utilizar el PREMIO, estarán a exclusivo cargo del Ganador.
        </li>
        <li>
          El PREMIO sólo podrá ser utilizado el 27 de enero de 2024, no se podrán modificar las fechas de reserva.
        </li>
      </ol>
    </div>
  </div>
  <div class="winners mt-7">
    <h3 class="text-lg font-bold">Ganadores:</h3>
    <div class="text-sm container mx-auto xl:px-80 sm:px-0">
      <p class="px-5 pt-3">
        Se sortearán 2 ganadores totales (cada uno de los ganadores podrá hacer goce el PREMIO con 1
        acompañante). <br>

        En el caso de ser sorteada la misma persona dos veces, se volverá a realizar el sorteo por uno
        de los PREMIO. Ningún usuario podrá hacer goce de más de un PREMIO. <br>

        Una vez sorteado el ganador, se verificará que tanto su usuario como el usuario etiquetado en el comentario que salió ganador,
        sigan a la cuenta de Durex.<br>

        Si el usuario sorteado ganador participó en varios comentarios etiquetando a diferentes personas, es
        la persona que salió sorteada en el comentario seleccionado quien debe seguir a la cuenta de @durex_cl.
        Si ese usuario etiquetado no sigue a la cuenta de Durex, el ganador no podrá hacer uso de su PREMIO. <br>

        El llamado es a que todos participen desde el miércoles 17 de enero de 2024 a las 18:00 hrs hasta el jueves 25 a las 20:00hs. <br>

        El sorteo se realizará de forma aleatoria el Martes 25 de enero de 2024 a partir de las 20:00hs,
        donde se obtendrán los ganadores (2 ganadores). Para el sorteo se utilizará una plataforma provista por un
        tercero independiente (como App wask, Easyprom, Sortea2, o similares) donde se sortearán a los 2 ganadores al azar. <br>

        Se elegirán a 2 ganadores que recibirán el PREMIO del cual podrán hacer uso con 1 (un) acompañante.<br>

        El ganador será notificado el día 25 de enero de 2025 por mensaje directo de Instagram con la explicación
        de cómo proceder y gestionar directamente su PREMIO, además, sus nombres serán publicados en los stories del
        Instagram Oficial @durex_cl. <br>

        El ganador deberá responder y confirmar la efectividad de su PREMIO antes del 26 de enero a las 18:00hs para proceder con la entrega.
        En el caso de no responder dentro del plazo indicado, se procederá con el sorteo de ese PREMIO para otro ganador. <br>
      </p>
    </div>
  </div>
  <div class="general-requirements mt-7">
    <h3 class="text-lg font-bold">Condiciones generales:</h3>
    <div class="text-sm container mx-auto xl:px-80 sm:px-0">
      <div class="px-5">
        <ol>
          <li>
            Los participantes podrán concursar válidamente en el CONCURSO que será gestionado por @durex_cl.
            Para participar del CONCURSO las personas interesadas deberán aceptar las presentes BASES, las
            que han sido informadas a los participantes en la difusión del CONCURSO. Para todos los efectos legales,
            al participar en la publicación del CONCURSO se entenderá como confirmación del Participante de haber aceptado las presentes BASES.
            </li>
          <li>
            Los participantes deberán ser mayores de 18 años de edad, en el caso de yo cumplir con este requisito,
            no se podrá hacer uso del PREMIO.
            </li>
          <li>
            No podrán participar en el CONCURSO los empleados de DUREX, ni aquellas personas que hayan participado directa
            o indirectamente en el diseño, puesta en marcha y/o cierre del CONCURSO, ni los familiares de las personas antedichas,
            hasta el primer grado de afinidad.
          </li>
          <li>
            Serán descalificados y quedarán excluidos del CONCURSO los participantes que no cumplan con
            todos los requisitos exigidos de manera precedente y/o quienes no los completen correctamente.
          </li>
          <li>
            La titularidad del PREMIO entregado bajo el CONCURSO, o los derechos sobre el mismo, no podrán cederse,
            transferirse o transmitirse de ningún modo.
            El PREMIO es personal, no puede cambiarse por un valor económico, y no permite cambios.
            </li>
          <li>
            La responsabilidad de DUREX en este CONCURSO, se limita exclusivamente a la realización del CONCURSO
            en la forma establecida en estas BASES, y a entregar el PREMIO conforme a las mismas y la normativa aplicable.
          </li>
          <li>
            La participación en la presente convocatoria supone la aceptación íntegra de las
            presentes BASES y condiciones.
            </li>
          <li>
            La participación en la presente convocatoria supone la aceptación íntegra de las presentes BASES y condiciones.
            </li>
          <li>
            El ganador no podrá exigir por el PREMIO, su valor en dinero, ni su cambio por un bien ni servicio distinto a los indicados en estas BASES.
          </li>
          <li>
            Los participantes del CONCURSO, por el solo hecho de participar en él, autorizan a DUREX a publicar su
            nombre de usuario de Instagram vía story en el caso de salir seleccionados como ganadores.
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div class="durex-responsability mb-20">
    <h3 class="text-lg font-bold">RESPONSABILIDAD</h3>
    <div class="text-sm container mx-auto xl:px-80 sm:px-0">
      <div class="px-5">
        DUREX no se responsabilizan por ningún daño personal o material o pérdida (directa, indirecta y/o
        consecuente) ocasionada a los Participantes y/o Potenciales Ganadores y/o Ganadores, y/o a
        terceras personas, con motivo o en ocasión de su participación y/o retiro del PREMIO y/o
        utilización del mismo. <br>
        Los Participantes eximen expresamente a DUREX de toda responsabilidad ocasionada por
        cualquier daño o perjuicio sufrido por estos, proveniente del caso fortuito o fuerza mayor, hechos
        de terceros y/o cualquier responsabilidad que no resultare imputable en forma directa.
        En caso de que el PREMIO se deba suspender temporal o definitivamente, por causas o motivos
        de fuerza mayor, DUREX no tendrá responsabilidad alguna frente a los Ganadores. DUREX no será
        responsable de los daños y perjuicios que pudieran sufrir los Ganadores en sus personas y/o
        bienes
      </div>
    </div>
  </div>
</div>
