import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-credentials-mx',
  templateUrl: './credentials-mx.component.html',
  styleUrls: ['./credentials-mx.component.scss']
})
export class CredentialsMxComponent implements OnInit {

  public url?: string | SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer, title: Title) {
    this.sanitizer = sanitizer;
    title.setTitle('Credenciales Eglow');
  }

  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.urlCredencialsCanvas);
  }

}
