import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appSvgString]'
})
export class SvgStringDirective implements OnInit {

  @Input() appSvgString?: string;

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
      this.el.nativeElement.innerHTML = this.appSvgString;
  }
}
