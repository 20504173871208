import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

export type Team = {
  name: string,
  job: string,
  photo: string
}

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  public team: Array<Team> = [];

  ngOnInit(): void {
      this.team = <Array<Team>>environment.team;
  }

}
