import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { CoverComponent } from './components/cover/cover.component';
import { TeamComponent } from './components/team/team.component';
import { SuccessfulCasesComponent } from './components/successful-cases/successful-cases.component';
import { CustomersComponent } from './components/customers/customers.component';
import { SvgStringDirective } from './directives/svg-string.directive';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { MapComponent } from './components/map/map.component';
import { CredentialsMxComponent } from './pages/credentials-mx/credentials-mx.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CredentialsClComponent } from './pages/credentials-cl/credentials-cl.component';
import { DurexComponent } from './pages/convocatorias/2023/chile/durex/durex.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    CoverComponent,
    TeamComponent,
    SuccessfulCasesComponent,
    CustomersComponent,
    SvgStringDirective,
    FooterComponent,
    MapComponent,
    CredentialsMxComponent,
    CredentialsClComponent,
    DurexComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
