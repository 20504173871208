<section id="nosotros" class="bg-gray-100 py-10">
  <div class="container mx-auto px-4">
    <h1 class="text-4xl font-bold text-center">Nosotros</h1>
    <p class="text-center text-lg"> Eglow consta de un equipo directivo de mujeres y un equipo de desarrollo de hombres. entre todos creamos grandes tecnologías que agregan valor a las campañas digitales por el mundo! </p>
  </div>
  <div class="container container-team mx-auto px-4">
    <div class="grid gap-4 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
      <ng-container *ngFor="let item of team">
        <div class="item-team">
          <img [src]="item.photo" loading="lazy" alt="">
          <div class="description flex flex-column">
            <h3 class="text-center text-white position">{{ item.name }}</h3>
            <h4 class="text-center text-white">{{ item.job }}</h4>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
