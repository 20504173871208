<div class="main-container container">
  <figure>
    <img class="logo" src="/assets/img/durex-logo.png" alt="">
  </figure>

  <div class="title px-10">
    <h2 class="text-3xl">¡BIENVENIDO AL</h2>
    <h1 class="text-4xl font-bold">FUTURO DUREX!</h1>
  </div>
  <div class="description px-10">
    <div>
      <h3 class="text-2xl font-bold">¡Ya eres uno de nosotros!</h3>
      <p class="text-2xl">Hoy elegiste y te llevamos al <span class="font-bold">siguiente nivel.</span></p>
    </div>

    <h3 class="text-2xl font-bold mt-5">ALERTA CONCURSO</h3>
    <p class="text-2xl font-bold">
      Gana 2 entradas para Magik Gardens Festival +
    </p>
    <p class="text-2xl font-bold">
      una Durex Box Gold
    </p>
  </div>
  <div class="requirements mt-7">
    <h3 class="text-2xl my-3">¿Cómo participar?</h3>
    <ol class="text-2xl">
      <li>Sigue a @durex_cl</li>
      <li>
        Si estuviste en Creamfilds y te encontraste al equipo Durex, <br>
        sube tu avatar etiquetándonos.
      </li>
      <li>
        Si no fuiste a Creamfilds, comenta en esta publicación con quien <br>
        compartirías el premio.
      </li>
      <li>
        Para doble chance repostea en historias <br>
        tu avatar favorito de este post con el hashtag #SorteoDurex.
      </li>
    </ol>
  </div>
  <div class="modalidites mt-7">
    <h3 class="text-lg font-bold">BASES CONCURSO</h3>
    <h4 class="text-lg mb-3">“Futuro Durex”</h4>
    <div class="container mx-auto xl:px-80 sm:px-0">
      <p class="text-sm px-5">
        DUREX (@durex_cl) ha decidido efectuar un concurso con una modalidad de participación y
        premiación doble, desde ahora denominado CONCURSO, el que se regirá por las bases y reglas que se
        exponen a continuación, desde ahora denominadas BASES: <br>
        Modalidad del CONCURSO: <br>
        El CONCURSO, tendrá 2 modalidades de participación a través de la red social Instagram, en el que
        podrás ganar tanto subiendo una storie compartiendo tu avatar de Creamfilds como comentando en la
        publicación realizada en la cuenta de @Durex_cl. Se tomará como prioridad a las personas que,
        además, reposteen en sus historias su avatar favorito posteado en el carrusel con el hashtag #SorteoDurex.
        La persona que participe en el CONCURSO debe si o si seguir a la cuenta de @durex_cl en Instagram.
      </p>
    </div>
  </div>
  <div class="awards mt-7">
    <h3 class="text-lg font-bold">PREMIO:</h3>
    <div class="container mx-auto xl:px-80 sm:px-0 text-sm">
      <p class="px-5 pb-2">En el contexto del CONCURSO podrás ganar:</p>

      <p class="px-5">
        • Una de las dos entradas dobles para asistir al evento Magik Garden Festival.
        • Una Durex Box Gold, con variedad de productos Durex dentro.
        Bajo las condiciones establecidas en estas BASES y en particular conforme a los siguientes
        términos (el “PREMIO”):
      </p>

      <ol class="mt-5 px-5">
        <li>
          El PREMIO será válido sólo para el ganador seleccionado de conformidad a lo
          dispuesto en estas BASES, no extendiéndose el beneficio a otros.
        </li>
        <li>
          El PREMIO sólo será efectivo para aquellos mayores de 18 años. Menores de edad no
          podrán hacer goce del PREMIO. Se le solicitará a los ganadores la información correspondiente
          para comprobar su identidad y su edad acorde a estas BASES.
        </li>
        <li>
          El PREMIO no incluirá ningún otro pago, prestación, bien o servicio distinto de
          aquello indicado expresamente en las presentes BASES, siendo intransferible y el
          Ganador no podrá cederlo. Todos los costos que se deriven de la participación o de la
          obtención del PREMIO , así como también los gastos de traslado, alimentos, bebidas,
          entre otros, en que incurra al utilizar el PREMIO, estarán a exclusivo cargo del Ganador.
        </li>
        <li>
          El PREMIO sólo podrá ser utilizado el día de la fecha del evento, no se podrán modificar las
          fechas pautadas
        </li>
      </ol>
    </div>
  </div>
  <div class="winners mt-7">
    <h3 class="text-lg font-bold">Ganadores:</h3>
    <div class="text-sm container mx-auto xl:px-80 sm:px-0">
      <p class="px-5 pt-3">
        Se sortearán 2 ganadores totales (cada uno de los ganadores podrá hacer goce el PREMIO con 1
        acompañante). <br>
        En el caso de ser sorteada la misma persona dos veces, se volverá a realizar el sorteo por uno
        de los PREMIO. Ningún usuario podrá hacer goce de más de un PREMIO. <br>
        Una vez sorteado el ganador, se verificará que siga a la cuenta de Durex y haya subido su avatar / reposteado y comentado. <br>
        El llamado es a que todos participen desde el lunes 16 de octubre de 2023 hasta el
        jueves 23 de noviembre.
      </p>

      <p class="px-5 pt-3">
        Para el sorteo se utilizará una plataformaprovista por un tercero independiente
        (como App wask, Easyprom, Sortea2, o similares) donde se
        sortearán a los 2 ganadores al azar.
      </p>

      <p class="px-5 pt-3">
        Se elegirán a 2 ganadores que recibirán el PREMIO del cual podrán hacer uso con 1 (un)
        acompañante. <br>
        El ganador será notificado el día 23 de noviembre de 2023 por mensaje directo de Instagram con la
        explicación de cómo proceder y gestionar directamente su PREMIO, además, sus nombres serán
        publicados en los stories del Instagram Oficial @durex_cl. <br>
        El ganador deberá responder y confirmar la efectividad de su PREMIO antes del 24 de noviembre
        para proceder con la entrega. En el caso de no responder dentro del plazo indicado, se
        procederá con el sorteo de ese PREMIO para otro ganador.
      </p>
    </div>
  </div>
  <div class="general-requirements mt-7">
    <h3 class="text-lg font-bold">Condiciones generales:</h3>
    <div class="text-sm container mx-auto xl:px-80 sm:px-0">
      <div class="px-5">
        <ol>
          <li>
            Los participantes podrán concursar válidamente en el CONCURSO que será gestionado por
            @durex_cl. Para participar del CONCURSO las personas interesadas deberán aceptar las presentes
            BASES, las que han sido informadas a los participantes en la difusión del CONCURSO. Para todos
            los efectos legales, al participar en la publicación del CONCURSO se entenderá como confirmación
            del Participante de haber aceptado las presentes BASES.
            </li>
          <li>
            Los participantes deberán ser mayores de 18 años de edad, en el caso de no cumplir con
            este requisito, no se podrá hacer uso del PREMIO.
            </li>
          <li>
            No podrán participar en el CONCURSO los empleados de DUREX, ni aquellas personas que
            hayan participado directa o indirectamente en el diseño, puesta en marcha y/o cierre del
            CONCURSO, ni los familiares de las personas antedichas, hasta el primer grado de afinidad.
            </li>
          <li>
            Serán descalificados y quedarán excluidos del CONCURSO los participantes que no cumplan
            con todos los requisitos exigidos de manera precedente y/o quienes no los completen
            correctamente.
            </li>
          <li>
            La titularidad del PREMIO entregado bajo el CONCURSO, o los derechos sobre el mismo, no
            podrán cederse, transferirse o transmitirse de ningún modo. El PREMIO es personal, no puede
            cambiarse por un valor económico, y no permite cambios.
            </li>
          <li>
            La responsabilidad de DUREX en este CONCURSO, se limita exclusivamente a la realización
            del CONCURSO en la forma establecida en estas BASES, y a entregar el PREMIO conforme a las
            mismas y la normativa aplicable.
            </li>
          <li>
            La participación en la presente convocatoria supone la aceptación íntegra de las
            presentes BASES y condiciones.
            </li>
          <li>
            El ganador no podrá exigir por el PREMIO, su valor en dinero, ni su cambio por un bien ni
            servicio distinto a los indicados en estas BASES.
            </li>
          <li>
            Los participantes del CONCURSO, por el solo hecho de participar en él, autorizan a DUREX
            a publicar su nombre de usuario de Instagram vía story en el caso de salir seleccionados como
            ganadores.
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div class="durex-responsability mb-20">
    <h3 class="text-lg font-bold">RESPONSABILIDAD</h3>
    <div class="text-sm container mx-auto xl:px-80 sm:px-0">
      <div class="px-5">
        DUREX no se responsabilizan por ningún daño personal o material o pérdida (directa, indirecta y/o
        consecuente) ocasionada a los Participantes y/o Potenciales Ganadores y/o Ganadores, y/o a
        terceras personas, con motivo o en ocasión de su participación y/o retiro del PREMIO y/o
        utilización del mismo. <br>
        Los Participantes eximen expresamente a DUREX de toda responsabilidad ocasionada por
        cualquier daño o perjuicio sufrido por estos, proveniente del caso fortuito o fuerza mayor, hechos
        de terceros y/o cualquier responsabilidad que no resultare imputable en forma directa.
        En caso de que el PREMIO se deba suspender temporal o definitivamente, por causas o motivos
        de fuerza mayor, DUREX no tendrá responsabilidad alguna frente a los Ganadores. DUREX no será
        responsable de los daños y perjuicios que pudieran sufrir los Ganadores en sus personas y/o
        bienes
      </div>
    </div>
  </div>
</div>
