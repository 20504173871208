import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-credentials-cl',
  templateUrl: './credentials-cl.component.html',
  styleUrls: ['./credentials-cl.component.scss']
})
export class CredentialsClComponent {

  constructor(private title: Title) {
    title.setTitle('Credenciales Eglow');
  }

}
