import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { SvgStringDirective } from 'src/app/directives/svg-string.directive';
import { environment } from 'src/environments/environment';

export type Category = {name: string, label: string, photo: string};
export type Customer = {photo: string, category: string, client: string};

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {

  public categories: Array<Category> = [];

  public clients: Array<Customer> = [];

  public categorySelected: string = 'all';

  public showAllCustomersInAllCategory: boolean = false;

  constructor() { }

  public ngOnInit(): void {
    this.setCategoriesAvailable();
  }

  public showContent(category: string): void {
    console.log('search category...', category);
    this.categorySelected = category;
    this.setClients(category);
  }

  private setCategoriesAvailable(): void {
    const categoriesAvailable = new Set([
      ...['all'],
      ...environment.customersLists.map(i => i.category),
    ]);
    let categories: Array<string> = [];
    categoriesAvailable.forEach((i) => {
      if (typeof i == 'string') {
        categories.push(i);
      }
    });
    const categoriesSorted = categories.sort();
    categoriesSorted.forEach(itemCategory => {
      const category: Category = environment.customersCategories.find((i) => i.name == itemCategory) as Category;
      if (category) {
        this.categories.push(category);
      }
    });
    this.setClients('all');
  }

  private setClients(category: string): void {
    this.categories.forEach(c => {
      if (category === 'all') {
        this.clients = environment.customersLists;
        return;
      }
      const clients: Array<Customer> = environment.customersLists.filter((i) => i.category == category);
      this.clients = clients;
    });

  }

  public showAll(): void {
    this.showAllCustomersInAllCategory = true;
  }
}
