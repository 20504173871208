<section id="mapa" class="bg-gray-100 py-10">
  <div class="container mx-auto p-5">
    <h1 class="text-4xl font-bold text-center">Siguenos en nuestras redes sociales</h1>
  </div>
  <div class="container social-networks mx-auto p-5">
    <div class="flex flex-column justify-center gap-10">
      <a href="https://www.facebook.com/eglowlatam/" title="Facebook" target="_blank">
        <svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Canvas" fill="none">
            <g id="facebook">
              <path id="Fill 1" fill-rule="evenodd" clip-rule="evenodd" d="M 11.7958 5.4084L 10.703 5.4084C 9.84538 5.4084 9.67953 5.81597 9.67953 6.41383L 9.67953 7.73233L 11.7238 7.73233L 11.7232 9.79654L 9.67953 9.79654L 9.67953 15.0943L 7.54717 15.0943L 7.54717 9.79654L 5.76453 9.79654L 5.76453 7.73233L 7.54717 7.73233L 7.54717 6.2094C 7.54717 4.44283 8.62588 3.48047 10.2022 3.48047L 11.7958 3.48304L 11.7958 5.4084ZM 9 0C 4.02943 0 0 4.02943 0 9C 0 13.9706 4.02943 18 9 18C 13.9712 18 18 13.9706 18 9C 18 4.02943 13.9712 0 9 0Z" transform="translate(-0.000244141 0)" fill="#00BDC5"/>
            </g>
          </g>
        </svg>
    </a>
    <a href="https://www.instagram.com/{{ instagramAccount }}/" title="Instagram" target="_blank">
      <svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Canvas" fill="none">
          <g id="instagram">
            <path id="Fill 1" fill-rule="evenodd" clip-rule="evenodd" d="M 2.11953 4.2101C 3.2786 4.20431 4.22167 3.25674 4.21524 2.10153C 4.20946 0.933455 3.26317 -0.00575913 2.09767 2.65876e-05C 0.938598 0.0058123 -0.00575927 0.955312 2.6444e-05 2.10924C 0.0051693 3.27474 0.954669 4.21588 2.11953 4.2101Z" transform="translate(6.7373 7.21606)" fill="#00BDC5"/>
            <path id="Fill 3" fill-rule="evenodd" clip-rule="evenodd" d="M 0.667049 2.10616C 0.92612 2.11195 1.18583 2.11195 1.44491 2.10616C 1.80876 2.0978 2.09805 1.80402 2.10641 1.43952C 2.11026 1.31223 2.10705 1.18495 2.10769 1.05766C 2.10769 0.929732 2.10962 0.802446 2.10705 0.675161C 2.09933 0.302304 1.81005 0.00980357 1.43783 0.003375C 1.18326 -0.001125 0.928049 -0.001125 0.673477 0.003375C 0.303192 0.00980357 0.0100487 0.303589 0.00362008 0.675161C -0.000879919 0.930375 -0.00152278 1.18495 0.00362008 1.44016C 0.0113344 1.80273 0.30512 2.0978 0.667049 2.10616Z" transform="translate(10.9352 5.13721)" fill="#00BDC5"/>
            <g id="Group 7">
              <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
                <path id="Clip 6" fill-rule="evenodd" clip-rule="evenodd" d="M 17.9999 0L 17.9999 17.9997L 0 17.9997L 0 0L 17.9999 0L 17.9999 0Z" transform="translate(0.00012207 0.000244141)" fill="white"/>
              </mask>
              <g mask="url(#mask0)">
                <path id="Fill 5" fill-rule="evenodd" clip-rule="evenodd" d="M 14.3242 12.551C 14.3222 13.831 13.3669 14.7817 12.0844 14.7837C 11.0051 14.7856 9.92637 14.7843 8.84702 14.7843C 7.7683 14.7843 6.68959 14.7875 5.61023 14.7837C 4.53409 14.7792 3.65466 14.0656 3.4438 13.0248C 3.4123 12.8699 3.40009 12.7092 3.40009 12.5504C 3.39687 10.3968 3.39623 8.24388 3.3988 6.09031C 3.39945 5.00067 4.1053 4.11995 5.14995 3.90524C 5.31002 3.87245 5.47523 3.85959 5.63852 3.85895C 7.78695 3.85638 9.93602 3.85638 12.0851 3.85831C 13.3676 3.85895 14.3222 4.81102 14.3242 6.09095C 14.3261 8.24452 14.3261 10.3975 14.3242 12.551ZM 9 0C 4.02879 0 0 4.02943 0 9C 0 13.9706 4.02879 18 9 18C 13.9706 18 18 13.9706 18 9C 18 4.02943 13.9706 0 9 0Z" transform="translate(0.00012207 0.000244141)" fill="#00BDC5"/>
              </g>
            </g>
            <path id="Fill 8" fill-rule="evenodd" clip-rule="evenodd" d="M 6.88311 2.826C 6.24346 3.67007 5.38396 4.12521 4.32646 4.16893C 3.36989 4.20814 2.53225 3.88543 1.85468 3.20979C 0.959177 2.31686 0.68532 1.23171 0.93732 0.00128571L 0.00517667 0.00128571L 0.00517667 0.143357C 0.00517667 1.51329 0.0122481 2.88386 3.38118e-05 4.25379C -0.00382333 4.71471 0.322748 5.02264 0.763748 5.022C 3.05361 5.01557 5.34346 5.01879 7.63332 5.01879C 8.11996 5.01879 8.39832 4.74171 8.39832 4.25957L 8.39832 0.1485L 8.39832 0L 7.46296 0C 7.68025 1.03179 7.52082 1.98321 6.88311 2.826Z" transform="translate(4.64435 8.50366)" fill="#00BDC5"/>
          </g>
        </g>
      </svg>
    </a>
    <a href="https://twitter.com/Eglow_Latam" title="Twitter" target="_blank">
      <svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Canvas" fill="none">
          <g id="twitter">
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
              <path id="Clip 2" fill-rule="evenodd" clip-rule="evenodd" d="M 0 0L 17.9999 0L 17.9999 17.9997L 0 17.9997L 0 0Z" transform="translate(0.00012207 0)" fill="white"/>
            </mask>
            <g mask="url(#mask0)">
              <path id="Fill 1" fill-rule="evenodd" clip-rule="evenodd" d="M 14.2181 7.03036C 14.2232 7.13258 14.2258 7.23608 14.2258 7.33893C 14.2258 7.81401 14.1686 8.30001 14.0599 8.78215C 13.4447 11.5098 11.1118 14.1384 7.31766 14.1384C 5.94709 14.1384 4.67037 13.7424 3.5968 13.0655C 3.78709 13.0867 3.97802 13.0976 4.17473 13.0976C 5.31387 13.0976 6.35852 12.7164 7.19037 12.0742C 6.12837 12.0562 5.23223 11.3632 4.92173 10.415C 5.07152 10.4433 5.22452 10.4594 5.37944 10.4594C 5.60059 10.4594 5.81594 10.4285 6.02037 10.3751C 5.20266 10.2131 4.53473 9.64615 4.23773 8.89722C 4.13102 8.62786 4.07252 8.33665 4.07252 8.03065L 4.07252 8.00108C 4.39973 8.17979 4.77452 8.28779 5.17309 8.30001C 4.52059 7.87122 4.09116 7.13965 4.09116 6.31101C 4.09116 5.87386 4.21266 5.46243 4.41966 5.10951C 5.6173 6.55465 7.40573 7.50608 9.42366 7.60636C 9.38316 7.43151 9.36066 7.24958 9.36066 7.06186C 9.36066 5.74272 10.449 4.67236 11.7887 4.67236C 12.4875 4.67236 13.1188 4.96293 13.5604 5.42708C 14.1139 5.31972 14.6334 5.12043 15.1027 4.84658C 14.9214 5.40522 14.5369 5.87386 14.0349 6.16893C 14.526 6.11108 14.9953 5.98251 15.4286 5.79286C 15.1039 6.27243 14.6919 6.69286 14.2181 7.03036ZM 9 0C 4.02879 0 0 4.03007 0 9C 0 13.9706 4.02879 18 9 18C 13.9706 18 18 13.9706 18 9C 18 4.03007 13.9706 0 9 0Z" transform="translate(0.00012207 -0.000244141)" fill="#00BDC5"/>
            </g>
          </g>
        </g>
      </svg>
    </a>
    <a href="https://www.youtube.com/channel/UCteXxGmps_9hlbQyuokC3Iw"  target="_blank"  title="youtube">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 49.226 48.406">
        <defs>
          <style>
            .cls-1 {
              fill: none;
            }

            .cls-2 {
              fill: #00bdc5;
            }

            .cls-3 {
              clip-path: url(#clip-path);
            }
          </style>
          <clipPath id="clip-path">
            <path id="Clip_3" data-name="Clip 3" class="cls-1" d="M49.226,48.406H0V0H49.226Z" transform="translate(0 0.761)"/>
          </clipPath>
        </defs>
        <g id="youtube" data-name="Page 1" transform="translate(0 -0.833)">
          <path id="Fill_1" data-name="Fill 1" class="cls-2" d="M0,0V1.959L0,8.4,7.876,4.216,3.551,1.9Z" transform="translate(21.602 20.515)"/>
          <g id="Group_4" data-name="Group 4" transform="translate(0 0.073)">
            <path id="Clip_3-2" data-name="Clip 3" class="cls-1" d="M49.226,48.406H0V0H49.226Z" transform="translate(0 0.761)"/>
            <g id="Group_4-2" data-name="Group 4" class="cls-3">
              <path id="Fill_2" data-name="Fill 2" class="cls-2" d="M24.614,48.407a24.665,24.665,0,0,1-17.4-7.089A23.912,23.912,0,0,1,1.934,14.784,24.11,24.11,0,0,1,7.209,7.09a24.905,24.905,0,0,1,34.808,0,24.109,24.109,0,0,1,5.274,7.694,23.915,23.915,0,0,1-5.274,26.533A24.663,24.663,0,0,1,24.614,48.407Zm-.006-34.713c-.061,0-6.168,0-10.2.3l-.186.02a3.975,3.975,0,0,0-2.736,1.246,6.614,6.614,0,0,0-1.157,2.97,46.73,46.73,0,0,0-.291,4.836v2.266a46.78,46.78,0,0,0,.291,4.839,6.6,6.6,0,0,0,1.157,2.966,4.244,4.244,0,0,0,2.823,1.221c.152.018.283.034.392.055,2.307.229,9.838.3,9.913.3s6.178-.017,10.207-.313l.189-.02a3.972,3.972,0,0,0,2.733-1.246A6.579,6.579,0,0,0,38.9,30.171a46.479,46.479,0,0,0,.292-4.839V23.065a46.455,46.455,0,0,0-.292-4.839,6.576,6.576,0,0,0-1.156-2.966,3.973,3.973,0,0,0-2.736-1.246l-.186-.02c-4.036-.3-10.139-.3-10.2-.3Z" transform="translate(0 0.76)"/>
            </g>
          </g>
        </g>
      </svg>
    </a>
    <a href="https://www.linkedin.com/company/eglow/" title="Linkedin"  target="_blank">
      <svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Canvas" fill="none">
          <g id="linkedin">
            <path id="Fill 1" fill-rule="evenodd" clip-rule="evenodd" d="M 14.2457 13.0951L 12.1764 13.0951L 12.1764 12.998L 12.1764 9.82034C 12.1764 9.54519 12.1577 9.27198 12.0645 9.00905C 11.9025 8.55262 11.5322 8.32827 11.0507 8.36041C 10.5519 8.39384 10.1764 8.75127 10.0504 9.17812C 10.0029 9.33691 10.0009 9.51241 10.0003 9.68019C 9.99708 10.7814 9.99901 11.8826 9.99901 12.9838L 9.99901 13.0931L 7.93158 13.0931L 7.93158 6.86641L 8.01451 6.86641C 8.64579 6.86641 9.27708 6.86705 9.90837 6.86448C 9.98165 6.86448 10.0016 6.88505 10.0003 6.95769C 9.99579 7.18462 9.99901 7.41219 9.99901 7.63912L 9.99901 7.72141C 10.1212 7.57741 10.2292 7.43726 10.35 7.30998C 10.6637 6.98212 11.0546 6.80341 11.5001 6.74555C 11.9327 6.68834 12.3583 6.72369 12.771 6.87669C 13.257 7.05669 13.6215 7.37877 13.8709 7.83069C 14.1596 8.35334 14.247 8.92484 14.2489 9.51176C 14.2522 10.6843 14.2502 11.8569 14.2496 13.0295C 14.2496 13.0501 14.247 13.0706 14.2457 13.0951ZM 6.90174 5.08763C 6.8516 5.50099 6.61695 5.78706 6.22545 5.92849C 6.07181 5.98442 5.90338 6.00177 5.74203 6.03649C 5.74074 6.02942 5.73945 6.02299 5.73752 6.01592C 5.33188 6.00885 4.98474 5.87899 4.7546 5.52542C 4.3631 4.9237 4.65174 4.13235 5.33895 3.92663C 5.73688 3.8077 6.1271 3.83149 6.47552 4.07642C 6.82331 4.3207 6.95188 4.67556 6.90174 5.08763ZM 6.77183 13.1002L 6.69019 13.1002C 6.05955 13.1002 5.42826 13.0989 4.79762 13.1021C 4.72305 13.1021 4.70569 13.0796 4.70569 13.0083C 4.70762 10.9916 4.70762 8.97436 4.70569 6.95772C 4.70569 6.88572 4.72369 6.8645 4.79826 6.8645C 5.4289 6.86707 6.06019 6.86643 6.69083 6.86643L 6.77183 6.86643L 6.77183 13.1002ZM 9 0C 4.02879 0 0 4.02943 0 9C 0 13.9706 4.02879 18 9 18C 13.9706 18 18 13.9706 18 9C 18 4.02943 13.9706 0 9 0Z" transform="translate(0.00012207 0)" fill="#00BDC5"/>
          </g>
        </g>
      </svg>
    </a>
    </div>
  </div>
  <div class="container mx-auto px-4">
    <div #map style="width:100%;height:300px"></div>
    <p class="text-white text-center">
      {{ location?.address }}
    </p>
  </div>
  <div class="container mx-auto p-5">
    <div class="flex flex-column justify-between gap-10">
      <div class="contact text-white">
        <h3 class="text-base font-bold uppercase">Contacto</h3>
        <ul *ngIf="contact">
          <li class="text-sm"><a href="mailto:{{ contact.email }}">Mail: {{ contact.email }}</a></li>
          <li class="text-sm"><a href="tel:{{ contact.phoneNumber }}">Tel: {{ contact.phoneLabel }}</a></li>
        </ul>
      </div>
      <div class="sections text-white">
        <h3 class="text-base font-bold uppercase">Compañia</h3>
        <ul>
          <li class="text-sm"><a href="#casos-exito">Casos de éxito</a></li>
          <li class="text-sm"><a href="#clientes">Clientes</a></li>
          <!-- <li><a href="#">Blog</a></li> -->
        </ul>
      </div>
      <div class="awards">
        <img src="/assets/img/corfo.png" alt="corfo">
        <img src="/assets/img/sum.png" alt="sum">
      </div>
    </div>
  </div>
</section>
