<nav class="navbar">
  <div class="container mx-auto px-4">
    <div class="flex items-center justify-between h-16">
      <div class="flex items-center items-menu">
        <img class="h-8 w-auto mr-4 logo" src="/assets/img/eglow-logo.png" alt="Logo">
        <div class="hidden md:block">
          <a class="text-white hover:text-white px-3 py-2 rounded-md" href="#inicio">Inicio</a>
          <a class="text-white hover:text-white px-3 py-2 rounded-md" href="#nosotros">Nosotros</a>
          <a class="text-white hover:text-white px-3 py-2 rounded-md" href="#casos-exito">Casos de Éxito</a>
          <a class="text-white hover:text-white px-3 py-2 rounded-md" href="#clientes">Clientes</a>
          <!-- <a class="text-white hover:text-white px-3 py-2 rounded-md" href="#blog">Blog</a> -->
          <a class="text-white hover:text-white px-3 py-2 rounded-md" (click)="openModal('CONTACT')">Contacto</a>
          <a class="text-white hover:text-white px-3 py-2 rounded-md eglow-button-green"(click)="openModal('DEMO')">Solicitar demo</a>
        </div>
      </div>
      <div class="block md:hidden">
        <button id="menu-toggle" class="text-white hover:text-white focus:outline-none focus:text-white" aria-label="Menú" (click)="clickToggle()">
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
    </div>
    <div id="menu" class="" [class]="!toggle() ? 'md:hidden hidden' : 'md:hidden'">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <a class="text-white hover:text-white block px-3 py-2 rounded-md" href="#inicio">Inicio</a>
        <a class="text-white hover:text-white block px-3 py-2 rounded-md" href="#nosotros">Nosotros</a>
        <a class="text-white hover:text-white block px-3 py-2 rounded-md" href="#casos-exito">Casos de Éxito</a>
        <a class="text-white hover:text-white block px-3 py-2 rounded-md" href="#clientes">Clientes</a>
        <a class="text-white hover:text-white block px-3 py-2 rounded-md" href="#blog">Blog</a>
        <a class="text-white hover:text-white block px-3 py-2 rounded-md" (click)="openModal('CONTACT')">Contacto</a>
        <a class="text-white hover:text-white block px-3 py-2 rounded-md" (click)="openModal('DEMO')">Solicitar demo</a>
      </div>
    </div>
  </div>
</nav>

<div id="modal" class="fixed inset-0 flex items-center justify-center z-50 hidden"
  [ngClass]="{'hidden': !modalIsOpen}">
  <div class="bg-white p-6 rounded shadow-lg w-1/2 container-modal">
    <img class="close_modal" src="/assets/img/close-icon.svg" alt="close-icon" title="Close Modal" (click)="closeModal()">
    <h2 class="text-lg font-semibold mb-4 title">{{ titleModal }}</h2>

    <div class="grid grid-cols-2 gap-4" [ngClass]="{'grid-rows-4': modalContactIsOpen, 'grid-rows-3': modalDemoIsOpen}" [formGroup]="contactForm">
      <div class="item-form">
        <label for="name">Name</label>
        <input name="name" type="text" class="form-control px-2" formControlName="name"  placeholder="Name">
        <label class="rounded-full bg-red my-2 input-error-message"
          *ngIf="contactForm.controls['name'].invalid && formIsSubmitted">
          The Name field is requerid
        </label>
      </div>
      <div class="item-form">
        <label for="email">Email</label>
        <input id="email" type="email" class="form-control px-2" formControlName="email" placeholder="Email">
        <label class="rounded-full bg-red my-2 input-error-message"
          *ngIf="contactForm.controls['email'].invalid && formIsSubmitted">
          The Email field is requerid
        </label>
      </div>
      <div class="item-form">
        <label for="company">Company Name</label>
        <input id="company" type="text" class="form-control px-2" formControlName="companyName" placeholder="Company Name">
        <label class="rounded-full bg-red my-2 input-error-message"
          *ngIf="contactForm.controls['companyName'].invalid && formIsSubmitted">
          The Company Name field is requerid
        </label>
      </div>
      <div class="item-form">
        <label for="telephone">Telephone Number</label>
        <input id="telephone" type="text" class="form-control px-2" formControlName="phoneNumber" placeholder="Telephone Number">
        <label class="rounded-full bg-red my-2 input-error-message"
          *ngIf="contactForm.controls['phoneNumber'].invalid && formIsSubmitted">
          The Telephone Number field is requerid
        </label>
      </div>
      <div class="item-form">
        <label for="employees">Employees</label>
        <select id="employees" formControlName="countEmployees" class="form-control">
          <option value="1-10" selected>1-10</option>
          <option value="11-20" >11-20</option>
          <option value="21-30" >21-30</option>
        </select>
      </div>
      <div class="item-form">
        <label for="country">Country</label>
        <select id="country"  formControlName="country" class="form-control">
          <option value="México">México</option>
          <option value="Chile">Chile</option>
          <option value="Colombia">Colombia</option>
          <option value="Argentina">Argentina</option>
        </select>
      </div>
      <div class="col-span-2 item-form" *ngIf="modalContactIsOpen">
        <label for="message">Message</label>
        <textarea id="message" formControlName="message" class="form-control px-2"></textarea>
        <label class="rounded-full bg-red my-2 input-error-message"
          *ngIf="contactForm.controls['message'].invalid && formIsSubmitted">
          The Message field is requerid
        </label>
      </div>
    </div>
    <div class="flex items-center">
      <label class="message-email-sended p-2" *ngIf="mailSentSuccessfully">Message sent successfully!</label>
      <button id="close-modal"
      (click)="validateContatForm()"
      class="button text-white px-2 my-2 rounded ml-auto text-white py-2 flex"
      >
        <div class="flex items-center justify-center mx-2" *ngIf="sendingEmail">
          <div class="animate-spin rounded-full h-5 w-5 border-t-2 border-blue-500"></div>
        </div>
        Send
      </button>
    </div>
  </div>
</div>

