import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-mx-providers',
  standalone: true,
  templateUrl: './mx-providers.component.html',
  styleUrls: ['./mx-providers.component.css'],
})
export class MxProvidersComponent implements OnInit{

  constructor(title: Title) {
    title.setTitle('Formulario de Proveedor - Eglow MX');
  }

  ngOnInit(): void {

  }

}
