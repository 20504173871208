<section id="casos-exito" class="py-10">
  <div class="container mx-auto px-4">
    <h1 class="text-4xl font-bold text-center text-white">Casos de exito</h1>
    <p class="text-white text-xl text-center py-2">A diferencia de todas las agencias que muestran casos de éxito, en eGLOW cuando hacemos las cosas bien entramos en éxtasis junto a nuestros clientes. Si buscas el triunfo y el éxtasis en tus campañas.</p>
  </div>
  <div class="list-items py-5">
      <div class="casos-exito-item" *ngFor="let item of cases">
        <img [src]="item.photo" alt="">
        <div class="description text-white">
          <h4 class="hashtag font-bold py-2">{{ item.title }}</h4>
          <p class="whitespace-normal py-2">{{ item.text }}</p>
          <ul class="list-none">
            <div [innerHTML]="item.metrics"></div>
          </ul>
        </div>
      </div>
  </div>
</section>
