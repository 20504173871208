import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-credentials-mx-en',
  templateUrl: `./credentials-mx-en.component.html`,
  styleUrls: ['./credentials-mx-en.component.css']
})
export class CredentialsMxEnComponent {

  constructor(private title: Title) {
    title.setTitle('Credentials Eglow');
  }

}
