<iframe
  style="
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    padding: 0;
    margin: 0;
  "
  src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAF2-LDdzww&#x2F;6UiB1qLh1UN2WD3Hyb3VrA&#x2F;view?embed"
  allowfullscreen="allowfullscreen"
  allow="fullscreen"
></iframe>
