<section id="clientes" class="bg-gray-100 py-10">
  <div class="container mx-auto px-4">
    <h1 class="text-4xl font-bold text-center">Clientes</h1>
    <p class="text-center text-lg">También llamados colegas, partners, compas, brothers, camaradas...No les decimos "holi amigui" pero sí consideramos que un cliente puede llegar a ser un amigo. Aquí un ejemplo de alguno de ellos.</p>
  </div>
  <div class="container mx-auto py-10">
    <div class="flex justify-center mb-4">
      <div class="grid grid-cols-8 gap-4">
        <ng-container *ngFor="let category of categories">
          <div class="item col-span-1 text-center p-1 cursor-pointer rounded-lg"
            [ngClass]="{'item-selected': categorySelected === category.name}"
            (click)="showContent(category.name)">
            <div [appSvgString]="category.photo">
            </div>
            <span class="text-center py-1 tex-black font-bold text-sm">{{ category.label }}</span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="content">
      <div class="container mx-auto py-10">
        <div class="flex justify-center">
          <div class="grid grid-cols-4 gap-4">
            <ng-container *ngFor="let client of clients; let i = index">
              <img src="{{ client.photo }}"
                alt="{{ client.client }}"
                loading="lazy"
                *ngIf="i <= 11 || showAllCustomersInAllCategory">
            </ng-container>
          </div>
        </div>
        <div class="flex justify-center my-5" *ngIf="!showAllCustomersInAllCategory && categorySelected == 'all'">
          <button class="show-more" (click)="showAll()">Show All</button>
        </div>
      </div>
    </div>
  </div>
</section>
