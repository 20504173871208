import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CredentialsMxComponent } from './pages/credentials-mx/credentials-mx.component';
import { CredentialsClComponent } from './pages/credentials-cl/credentials-cl.component';
import { DurexComponent } from './pages/convocatorias/2023/chile/durex/durex.component';
import { DurexHernanCattaneoComponent } from './pages/convocatorias/2023/chile/durex-hernan-cattaneo/durex-hernan-cattaneo.component';
import { CredentialsMxEnComponent } from './pages/credentials-mx-en/credentials-mx-en.component';
import { MxProvidersComponent } from './pages/mx-providers/mx-providers.component';
import { CredentialsClEnComponent } from './pages/credentials-cl-en/credentials-cl-en.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'credenciales-mexico-2024', component: CredentialsMxComponent },
  { path: 'credenciales-chile-2024', component: CredentialsClComponent },
  { path: 'credentials-mexico-2024', component: CredentialsMxEnComponent },
  { path: 'credentials-chile-2024', component: CredentialsClEnComponent },
  { path: 'durex-en-cremfilds', component: DurexComponent },
  { path: 'durex-hernan-cattaneo', component: DurexHernanCattaneoComponent },
  { path: 'formulario-proveedor-mx', component: MxProvidersComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
