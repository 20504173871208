import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-durex-hernan-cattaneo',
  templateUrl: './durex-hernan-cattaneo.component.html',
  styleUrls: ['./durex-hernan-cattaneo.component.css'],
})
export class DurexHernanCattaneoComponent {

  constructor(title: Title) {
    title.setTitle('Bases y Condiciones sorteo Durex');
  }


}
