import { Component, type OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-credentials-cl-en',
  templateUrl: './credentials-cl-en.component.html',
  styleUrls: ['./credentials-cl-en.component.css']
})
export class CredentialsClEnComponent implements OnInit {

  constructor(title: Title) {
    title.setTitle('Credentials Eglow');
  }

  ngOnInit(): void { }

}
